import React from "react";
import { Box, Flex } from "rebass/styled-components";

import { KendraResponseCategory, Page } from "../../../shared/services/kendra-api/kendra-api-service";
import "./page-content.scss";

export type PageContentProps = {
  page: Page;
  elementId?: string;
  isKyruusData?: boolean;
};

const PageContent: React.FC<PageContentProps> = (props: PageContentProps) => {
  const { page } = props;

  const result = (
    <Box my={[1, 1, 1, 1, 2]} className="no-decoration card-wrapper results-card">
      <Flex
        as="a"
        className={`search-content-card no-decoration ${props?.isKyruusData ? "kyruus-content-card" : ""}`}
        href={page?.source_uri}
        id={props.elementId}
        target={page._category === KendraResponseCategory.Articles ? "_blank" : "_self"}
        rel="noopener noreferrer"
        data-kendra-token={page.feedback_token}
        flexDirection="row"
        alignItems="c"
        my={[2, 2, 2, 3]}
      >
        <div className="card-image-wrapper">
          <img
            className="card-image"
            src={page._category === KendraResponseCategory.Pages && page.image_url ? page.image_url : "/pages-placeholder-image.svg"}
          />
        </div>
        <Flex paddingX={[3, 3, 3, 3, 4]} flexDirection="column" className="text-area">
          <span className="h6 card-title">{page?.title}</span>
          <span className="body-small matched-text">{page?.paragraph}</span>
        </Flex>
      </Flex>
    </Box>
  );

  return result;
};

export default PageContent;
