import { useCallback, useEffect, useState } from 'react';
import LocationApiService, { SuggestedLocation, SuggestedLocationApiProps } from '../../services/location-api/location-api-service';

export type ApiServiceStatus = {
  response: Array<SuggestedLocation>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

/**
 * This Suggested Location Api Hook is used to fetch data from the AWS Location service at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries
 * @returns an array of locations
 */
const useSuggestedLocationApi = (suggestedLocationProps: SuggestedLocationApiProps): [ApiServiceStatus] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState(new Array<SuggestedLocation>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      response: response,
      error: error
    });

  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);

    if (suggestedLocationProps?.text && suggestedLocationProps?.text?.length > 2) {
      try {
        const response = await LocationApiService.getSuggestedLocationResults(suggestedLocationProps);

        setIsRunning(false);
        setResponse(response);
      } catch (err) {
        setError(err);
        setHasError(true);
        setIsRunning(false);
      }
    }

  }, [suggestedLocationProps.text]);

  useEffect(() => {
    apiCall();
  }, [suggestedLocationProps.text]);

  return [apiStatus];
};

export default useSuggestedLocationApi;
