import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";

import ShIcon from "../../../shared/components/sh-icon/sh-icon";
import { Media } from "../../../shared/contexts/media/MediaContext";
import { UiStateContext } from "../../../shared/contexts/ui-state/UiStateContext";
import useGetLocationHours from "../../../shared/hooks/get-location-hours/useGetLocationHours";
import { IconPack, KyruusDailyAvailability, LocationHoursLabels, GlobalLocationPageIconPack } from "../../../shared/models/app-data-model";
import { Location } from "../../../shared/services/kendra-api/kendra-api-service";
import "./location-card.scss";

export type LocationCardProps = {
  location: Location;
  iconPack: IconPack[];
  elementId?: string;
  isKyruusData?: boolean;
  kyruusOriginCity?: string;
  kyruusTabCard?: boolean;
  kyruusHoursLabels?: LocationHoursLabels;
  locationAvailability?: KyruusDailyAvailability[];
  locationIconPack?: GlobalLocationPageIconPack;
};

const LocationCard: React.FC<LocationCardProps> = ({
  location,
  iconPack,
  elementId,
  isKyruusData,
  kyruusOriginCity,
  kyruusTabCard,
  kyruusHoursLabels,
  locationAvailability,
  locationIconPack
}: LocationCardProps) => {
  const uiStateContext = useContext(UiStateContext);
  const [desktopCardWidth, setDesktopCardWidth] = useState<string | string[]>("696px");

  const usingMlocPages = location._source_uri.includes("://");

  const locationHours: KyruusDailyAvailability[] = locationAvailability || [];
  const { getHoursLabel } = useGetLocationHours(locationHours, kyruusHoursLabels || ({} as LocationHoursLabels));

  let hasHours;

  if (isKyruusData && locationAvailability) {
    hasHours = locationAvailability?.some((sched: KyruusDailyAvailability) => {
      return sched.open_time && sched.open_time.trim() !== "";
    });
  }

  useEffect(() => {
    setDesktopCardWidth(
      uiStateContext.searchOpen || uiStateContext.embeddedSearchShown ? ["560px", "560px", "560px", "560px", "560px", "696px"] : "696px"
    );
  }, [uiStateContext.embeddedSearchShown, uiStateContext.searchOpen]);

  const defaultCardContent = (
    <>
      <Flex justifyContent="space-between" alignItems="center" flexDirection="row">
        <h6 className="location-title">{location.location_name}</h6>
        {location.location_rating !== "null" && (
          <Flex alignItems="center" justifyContent="space-between" flexDirection="row" flexShrink="0">
            <ShIcon iconPack={iconPack} className="star-icon" iconName="star" />
            <span className="overline rating secondary-text">{location.location_rating}</span>
            <span className="overline reviews secondary-text">({location.location_reviews})</span>
          </Flex>
        )}
      </Flex>

      {location.location_hours !== "null" && (
        <Flex flexDirection="row" alignItems="center">
          <ShIcon iconPack={iconPack} iconName="clock" className="clock" />
          <span className="body-small secondary-text location-hours">{location.location_hours}</span>
        </Flex>
      )}

      {location.street_address && location.street_address !== "null" && (
        <Flex alignItems="center">
          <ShIcon iconPack={iconPack} iconName="map_pin" className="map-pin" />
          <span className="address body-small secondary-text">
            {location.street_address +
              (location.city !== "null" && location.state !== "null" && location.zip_code !== "null"
                ? `, ${location.city}, ${location.state} ${location.zip_code}`
                : "")}
          </span>

          {location.distance && location.distance !== "null" && (
            <span className="body-small secondary-text">
              <span>
                <strong>{location.distance}</strong> miles from
              </span>
              <span className="user-location">
                {uiStateContext?.zipLocation?.zip?.value?.length === 5
                  ? uiStateContext?.zipLocation?.zip.value
                  : uiStateContext?.browserLocation?.latitude === 0
                  ? "Grand Rapids"
                  : "Your Location"}
              </span>
            </span>
          )}
        </Flex>
      )}
    </>
  );

  const kyruusLocationContent = (
    <>
      <Flex className="card-top" justifyContent="flex-start">
        <div className="card-image-wrapper">
          <img className="card-image" src="/locations-placeholder-image.svg" />
        </div>
        <Flex className="info-container kyruus-info-container" justifyContent="flex-center">
          <h6 className="location-name kyruus-loc-name">{location.location_name}</h6>
        </Flex>
      </Flex>
      <Flex className="bottom-row" flexDirection="column" justifyContent="space-between" width={1}>
        {location.location_type !== " " && (
          <Flex className="location-type-tag-wrapper" justifyContent="start">
            <li className="extra-small-text location-type">{location.location_type}</li>
          </Flex>
        )}
        <Flex className="location-info-wrapper" flexDirection="column">
          {location.location_hours.length > 0 && hasHours && (
            <Flex alignItems="center" flexDirection="row">
              <ShIcon iconPack={iconPack} icon={locationIconPack?.clock} className="inverse-clock-icon" />
              <span className="small-text kyruus-location-hours" data-testid="location-hours-test-id">
                {getHoursLabel()}
              </span>
            </Flex>
          )}
          {location.street_address !== " " && (
            <>
              <Flex className="location-address" alignItems="center" flexDirection="row">
                <ShIcon iconPack={iconPack} icon={locationIconPack?.map_pin} className="inverse-map-pin-icon" />
                <span className="small-text kyruus-mobile-secondary-text">
                  {location.street_address +
                    (location.city !== " " && location.state !== " " && location.zip_code !== " "
                      ? `, ${location.city}, ${location.state} ${location.zip_code}`
                      : "")}
                </span>
              </Flex>
              {location.distance && kyruusOriginCity && (
                <>
                  <Flex flexDirection="row">
                    <span className="small-text kyruus-mobile-secondary-text distance-wrapper">
                      <span className="distance-indicator-wrapper">{location.distance}</span> miles from{` ${kyruusOriginCity}`}
                    </span>
                  </Flex>
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </>
  );

  return (
    <a
      href={location._source_uri}
      className="no-decoration results-card"
      id={elementId}
      target={`${usingMlocPages ? "_blank" : "_self"}`}
      rel="noopener"
      data-kendra-token={location.feedback_token}
    >
      <Media lessThan="desktop">
        <Flex alignItems="center" className={`location-card ${isKyruusData ? "kyruus-location-card" : ""}`} flexDirection="column">
          {isKyruusData ? kyruusLocationContent : defaultCardContent}
        </Flex>
      </Media>

      <Media greaterThanOrEqual="desktop">
        <Flex
          alignItems="center"
          marginY={3}
          className={`location-card ${isKyruusData ? "kyruus-location-card" : ""} ${kyruusTabCard && "kyruus-tab-card"}`}
          width={desktopCardWidth}
          data-testid={`location-card-${desktopCardWidth}`}
        >
          <Flex className="info-container" flexDirection="column" width={1} height="100%">
            {isKyruusData ? kyruusLocationContent : defaultCardContent}
          </Flex>
        </Flex>
      </Media>
    </a>
  );
};

export default LocationCard;
