import { useCallback, useEffect, useState } from 'react';
import LocationApiService,  { LatLngApiProps, LocationObject } from '../../services/location-api/location-api-service';

export type ApiServiceStatus = {
  response: Array<LocationObject>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

/**
 * This Location Api Hook is used to fetch data lat/long data from the AWS Location service at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries
 * @returns an array of locations
 */
const useLatLngApi = (latlongProps: LatLngApiProps): [ApiServiceStatus] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState(new Array<LocationObject>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      response: response,
      error: error
    });

  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);

    if (latlongProps?.latitude && latlongProps?.longitude) {
      try {
        const response = await LocationApiService.getLatLngResults(latlongProps) as LocationObject[];

        setIsRunning(false);
        setResponse(response);
      } catch (err) {
        setError(err);
        setHasError(true);
        setIsRunning(false);
      }
    }

  }, [latlongProps.latitude, latlongProps.longitude]);

  useEffect(() => {
    apiCall();
  }, [latlongProps.latitude, latlongProps.longitude]);

  return [apiStatus];
};

export default useLatLngApi;
