import httpClient from "../http-client/http-client";

export type SuggestedLocationApiProps = {
  text: string;
};

export type SuggestedLocationApiResponse = {
  data: Array<SuggestedLocation>;
};

export type SuggestedLocation = {
  Text: string;
  PlaceId: string;
};

export type PlaceidApiProps = {
  placeId: string;
};

export type PlaceidApiResponse = {
  data: Place;
}

export type Place = {
  Label: string;
  Geometry: {
    Point: Array<number>;
  };
  Neighborhood: string;
  Municipality: string;
  SubRegion: string;
  Region: string;
  Country: string;
  PostalCode: string;
  Interpolated: boolean;
  AddressNumber?: string;
  Street?: string;
};

export type LatLngApiProps = {
  latitude: string;
  longitude: string;
};

export type LatLngApiResponse = {
  data: LocationObject;
};

export type LocationObject = {
  Distance: Number;
  Place: Place;
};

const LocationApiService = {

  getSuggestedLocationResults(suggestedLocationProps: SuggestedLocationApiProps) {
    const baseUrl = process.env.GATSBY_LOCATION_API_ENDPOINT;
    const key = process.env.GATSBY_LOCATION_APIKEY;

    if (suggestedLocationProps !== undefined && baseUrl !== undefined && key !== undefined) {
      const headers = { "x-api-key": key, "Content-Type": "application/json" };
      const path = `${baseUrl}/suggestion/{proxy+}?text=${suggestedLocationProps.text}`;

      return httpClient
        .get(path, headers)
        .then((response: SuggestedLocationApiResponse) => {
          return response.data;
        })
        .catch((e) => {
          throw new Error(`Error when calling Location API text endpoint: ${e}`);
        });
    }
    return [];
  },

  getPlaceResults(placeidProps: PlaceidApiProps) {
    const baseUrl = process.env.GATSBY_LOCATION_API_ENDPOINT;
    const key = process.env.GATSBY_LOCATION_APIKEY;

    if (placeidProps !== undefined && baseUrl != undefined && key != undefined) {
      const headers = { "x-api-key": key, "Content-Type": "application/json" };
      const path = `${baseUrl}/place/{proxy+}?placeId=${placeidProps.placeId}`;

      return httpClient
        .get(path, headers)
        .then((response: PlaceidApiResponse) => {
          return response.data;
        })
        .catch((e) => {
          throw new Error(`Error when calling Location API place endpoint: ${e}`);
        });
    } 
    return [];
  },

  getLatLngResults(latlngProps: LatLngApiProps) {
    const baseUrl = process.env.GATSBY_LOCATION_API_ENDPOINT;
    const key = process.env.GATSBY_LOCATION_APIKEY;

    if (latlngProps !== undefined && baseUrl !== undefined && key !== undefined) {
      const headers = { "x-api-key": key, "Content-Type": "application/json" };
      const path = `${baseUrl}/latlng/{proxy+}?longitude=${latlngProps.longitude}&latitude=${latlngProps.latitude}`;

      return httpClient
        .get(path, headers)
        .then((response: LatLngApiResponse) => {
          return response.data;
        })
        .catch((e) => {
          throw new Error(`Error when calling Location API place endpoint: ${e}`);
        });
    }
    return [];
  }
};

export default LocationApiService;
