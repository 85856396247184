import { graphql, StaticQuery } from "gatsby";
import React, { ReactNode } from "react";

import { GlobalQueryResponse } from "../../../design-system/shared/models/app-data-model";
import App from "../app";

export type LayoutProps = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          shSpectrumhealthOrgGlobalSettings {
            ...globalSettingsFragment
          }
          shGlobalHeaderNav {
            ...globalNavFragment
          }
          shSpectrumhealthOrgPrimaryHeader {
            ...primaryHeaderFragment
          }
          shGlobalAlertsSpectrumhealthOrg {
            ...globalAlertsFragment
          }
          shSpectrumhealthOrgGlobalFooterNav {
            ...globalFooterFragment
          }
          shGlobalToolbar {
            ...globalToolbarFragment
          }
          shRegionalLinksModal {
            ...regionalLinksModalFragment
          }
          shGlobalEmbedScriptsSpectrumhealthOrg(uid: { eq: "blt481f9aa675d2024b" }) {
            modular_blocks {
              embed_information {
                title
                div_id
                script
                enabled
                allow_list
                deny_list
                optional_attributes
                embedded_page_references {
                  ... on sh_pages {
                    url
                  }
                  ... on sh_mobile_app_pages {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      // NOTE: we're going to wrap our actual app-layout in app.tsx
      render={(data: GlobalQueryResponse) => <App data={data}>{children}</App>}
    />
  );
};

export default Layout;
