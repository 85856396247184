export const isTimeGreaterThanNow = (now: Date, time24: string) => {
  const [hour, minute] = time24.split(":").map(Number);
  const inputTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute);
  return inputTime >= now;
};

export const convertTo12HourFormat = (time24: string) => {
  const [hour, minute] = time24.split(":").map(Number);
  const suffix = hour >= 12 ? "pm" : "am";
  const hour12 = hour % 12 || 12;
  return `${hour12}:${String(minute).padStart(2, "0")}${suffix}`;
};
