import React, { useCallback, useEffect, useState } from 'react';

export type CardImageProps = {
  url: string;
  alt: string;
  className: string;
  type: 'provider' | 'location';
};

const CardImage: React.FC<CardImageProps> = ({ url, alt, className, type }: CardImageProps) => {
  const [imageUrl, setImageUrl] = useState(url);
  const defaultImageUrl = type === 'provider' ? '/profile.png' : '/locations-placeholder-image.svg';

  const handleImageError = useCallback(() => {
    setImageUrl(defaultImageUrl);
  }, [defaultImageUrl]);

  useEffect(() => {
    const src = !url || url === 'null' || url === " " ? defaultImageUrl : url;
    setImageUrl(src);
  }, [url, defaultImageUrl]);

  return <img className={className} src={imageUrl} alt={alt} onError={() => handleImageError()} data-testid="profile-image" />;
};

export default CardImage;
