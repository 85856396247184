import {
  convertTo12HourFormat,
  isTimeGreaterThanNow
} from "../../../page/location-detail-page/location-blocks/location-hours/location-hours-utils";
import { LocationHoursLabels, KyruusDailyAvailability } from "../../models/app-data-model";

export type LocationHoursProps = {
  dailyAvailability: KyruusDailyAvailability[];
  labels: LocationHoursLabels;
};

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const useGetLocationHours = (dailyAvailability: KyruusDailyAvailability[], labels: LocationHoursLabels) => {
  const now = new Date();
  const currentDay = daysOfWeek[now.getDay()];

  /**
   * isOpen24Hours
   * returns if the location is open for 24 hour a particular day
   */
  const isOpen24Hours = (schedule: KyruusDailyAvailability) => {
    return schedule?.open_time?.trim() == "00:00" && schedule?.close_time?.trim() == "24:00" ? true : false;
  };

  /**
   * getDailySchedule
   * returns the open/close hours for a specific day of the week
   */
  const getDailySchedule = (dayOfWeek: string): KyruusDailyAvailability | undefined => {
    const schedule = dailyAvailability?.find((sched: KyruusDailyAvailability) => sched.open_day.toLowerCase() === dayOfWeek.toLowerCase());
    return schedule;
  };

  /**
   * findNextOpenDay
   * finds the next open day in a schedule
   */
  const findNextOpenDay = (): KyruusDailyAvailability => {
    let nextAvailableDay;

    // find the next available day later in the same week
    nextAvailableDay = dailyAvailability?.find(
      (sched: KyruusDailyAvailability) =>
        sched.open_time.trim() != "" && daysOfWeek.indexOf(sched.open_day.toLowerCase()) > daysOfWeek.indexOf(currentDay.toLowerCase())
    );

    //if this is no available day in the same week, find the first open day in the weekly schedule
    if (!nextAvailableDay) {
      nextAvailableDay = dailyAvailability?.find(
        (sched: KyruusDailyAvailability) =>
          sched.open_time.trim() != "" && daysOfWeek.indexOf(sched.open_time.toLowerCase()) != daysOfWeek.indexOf(currentDay.toLowerCase())
      );
    }

    return nextAvailableDay as KyruusDailyAvailability;
  };

  const getHoursLabel = (): string => {
    let hoursLabel = labels?.hours_label;
    const currentDaySchedule = getDailySchedule(currentDay);

    if (isOpen24Hours(currentDaySchedule as KyruusDailyAvailability)) {
      //If open 24 hours always return Open 24 Hours label
      hoursLabel = "Open 24 hours";
    } else if (
      currentDaySchedule &&
      currentDaySchedule.close_time.trim() != "" &&
      isTimeGreaterThanNow(now, currentDaySchedule.close_time)
    ) {
      //If not open 24 hours and the close time for the current day is greater than the current time show "Open today until" label
      const closeTime = currentDaySchedule?.close_time && convertTo12HourFormat(currentDaySchedule?.close_time);
      hoursLabel = `${labels?.open_today_until} ${closeTime}`;
    } else {
      // If not 24 hours and the location is no longer open on current day, find the next open day/time
      const nextOpenDay = findNextOpenDay();
      const openTime = nextOpenDay && convertTo12HourFormat(nextOpenDay.open_time);
      const currentDayIndex = daysOfWeek.indexOf(currentDay.toLowerCase());

      if (daysOfWeek.indexOf(nextOpenDay?.open_day.toLowerCase()) === currentDayIndex + 1) {
        // if the next opend day is tomorrow (current day + 1), then show "Open tomorrow at" label
        hoursLabel = `${labels?.open_tomorrow_at} ${openTime}`;
      } else {
        // if the next opend day is not tomorrow (current day + 1), then show "Open on" label
        hoursLabel = `${labels?.open_on} ${nextOpenDay?.open_day} ${labels?.open_at} ${openTime}`;
      }
    }
    return hoursLabel;
  };

  return { getHoursLabel, getDailySchedule, isOpen24Hours };
};
export default useGetLocationHours;
