import React, { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Box } from "rebass/styled-components";

import Divider from "../../shared/components/divider/divider";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import useSearchLocationFilters, {
  SearchLocationFilters,
  SearchLocationSort
} from "../../shared/hooks/search-locations-sort/useSortLocationResults";
import { DeviceType } from "../../shared/hooks/use-breakpoint/device-type";
import useBreakpoint from "../../shared/hooks/use-breakpoint/useBreakpoint";
import usePagination from "../../shared/hooks/use-pagination/usePagination";
import { ZipDistance } from "../../shared/hooks/zip-distance/useZipDistance";
import { FeatureToggle, GlobalSearchNoResultsContent, IconPack } from "../../shared/models/app-data-model";
import { Location } from "../../shared/services/kendra-api/kendra-api-service";
import NoResults from "../no-results/no-results";
import Paginator from "../paginator/paginator";
import SearchClearFilters from "../search-clear-filters/search-clear-filters";
import SearchMobileHeader from "../search-mobile-header/search-mobile-header";
import SearchTotalResults from "../search-total-results/search-total-results";
import LocationCard from "./location-card/location-card";
import LocationFilter from "./location-filter/location-filter";
import "./locations.scss";

export type LocationSearchResultProps = {
  locations: Array<Location>;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  searchSuggestions?: string[];
  noResultsFoundContent?: GlobalSearchNoResultsContent;
};

const Locations: React.FC<LocationSearchResultProps> = ({
  locations,
  iconPack,
  featureToggles,
  searchSuggestions,
  noResultsFoundContent
}: LocationSearchResultProps) => {
  const deviceType = useBreakpoint();
  const filteredResults = useSearchLocationFilters(locations);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showClearFilters, setShowClearFilter] = useState(false);
  const [currentPageData, paginationDetails, setCurrentPage] = usePagination(filteredResults);
  const uiStateContext = useContext(UiStateContext);
  const mobileMarginTop = "54px";

  const onPageChange = useCallback(
    (currentPage: number): void => {
      setCurrentPage(currentPage);
      window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    [setCurrentPage]
  );

  const handleFilterSelectChange = useCallback(
    (newFilter?: SearchLocationFilters): void => {
      uiStateContext.setLocationFilters(newFilter);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleZipChange = useCallback(
    (newZip?: ZipDistance): void => {
      uiStateContext.setZipLocation(newZip);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleSortSelectChange = useCallback(
    (newSort?: SearchLocationSort): void => {
      uiStateContext.setLocationSort(newSort);
      setCurrentPage(1);
    },
    [uiStateContext, setCurrentPage]
  );

  const handleClearFilter = useCallback((): void => {
    uiStateContext.setLocationFilters?.(undefined);
    uiStateContext.setZipLocation?.(undefined);
    uiStateContext.setLocationSort({} as SearchLocationSort);
    setCurrentPage(1);
  }, [uiStateContext, setCurrentPage]);

  useEffect(() => {
    setShowClearFilter(
      Object.values(uiStateContext.locationFilters || {}).some((x) => x !== undefined) ||
        Object.values(uiStateContext.locationSort || {}).some((x) => x !== undefined) ||
        Object.values(uiStateContext.zipLocation || {}).some((x) => x !== undefined)
    );
  }, [uiStateContext.locationFilters, uiStateContext.locationSort, uiStateContext.zipLocation]);

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [deviceType]);

  useEffect(() => {
    if (deviceType > DeviceType.Mobile) {
      window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [deviceType, uiStateContext.locationFilters, showClearFilters]);

  const locationsList = (
    <Flex flexDirection="column">
      {(currentPageData as Location[])?.map((item, index) => (
        <Box key={item.location_id}>
          <LocationCard
            iconPack={iconPack}
            key={`location-card-${item.location_id}`}
            location={item as Location}
            elementId={`results-card-${index}`}
          />
        </Box>
      ))}
    </Flex>
  );

  const desktopView = (
    <>
      {filteredResults?.length > 0 ? (
        <Flex flexDirection="column" marginTop={2}>
          <SearchTotalResults count={filteredResults.length} />
          {locationsList}
          <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
        </Flex>
      ) : (
        <NoResults
          content={noResultsFoundContent}
          iconPack={iconPack}
          searchSuggestions={searchSuggestions}
          featureToggles={featureToggles}
        />
      )}

      <Flex flexDirection="column" alignItems="flex-start" className="search-filters-wrapper" width={1 / 3}>
        <Box className="search-filter-option" py={2}>
          <span className="body-small">Refine your search</span>
        </Box>
        <LocationFilter
          onFilterSelectChange={handleFilterSelectChange}
          onSortSelectChange={handleSortSelectChange}
          onZipSelectChange={handleZipChange}
        />
        {showClearFilters && (
          <>
            <Divider className="search-divider" />
            <SearchClearFilters iconPack={iconPack} clearFilter={handleClearFilter} />
          </>
        )}
      </Flex>
    </>
  );

  const mobileView = (
    <>
      <Flex flexDirection="column" width={1} className="mobile-view-container">
        <SearchMobileHeader
          showMobileFilters={showMobileFilters}
          setShowMobileFilters={setShowMobileFilters}
          showClearFilters={showClearFilters}
          clearFilter={handleClearFilter}
          iconPack={iconPack}
        />
        {showMobileFilters ? (
          <Box marginTop="60px">
            <LocationFilter
              onFilterSelectChange={handleFilterSelectChange}
              onSortSelectChange={handleSortSelectChange}
              onZipSelectChange={handleZipChange}
            />
          </Box>
        ) : filteredResults?.length > 0 ? (
          <Box marginTop={mobileMarginTop}>
            <SearchTotalResults count={filteredResults.length} />
            {locationsList}
            <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} />
          </Box>
        ) : (
          <Box marginTop={mobileMarginTop}>
            <NoResults
              content={noResultsFoundContent}
              iconPack={iconPack}
              searchSuggestions={searchSuggestions}
              featureToggles={featureToggles}
            />
          </Box>
        )}
      </Flex>
    </>
  );

  return <>{deviceType === DeviceType.Desktop ? desktopView : mobileView}</>;
};

export default Locations;
