import { useCallback, useEffect, useState } from 'react';
import LocationApiService, { PlaceidApiProps, Place } from '../../services/location-api/location-api-service';

export type PlaceidApiServiceStatus = {
  response: Array<Place>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

/**
 * This PlaceID Api Hook is used to fetch data from the AWS Location service at RUNTIME
 * (instead of at buildtime like our graphql)
 * @param queries
 * @returns an array of locations
 */

const usePlaceApi = (placeidProps: PlaceidApiProps): [PlaceidApiServiceStatus] => {
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState(new Array<Place>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as PlaceidApiServiceStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      response: response,
      error: error
    });

  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);

    if (placeidProps?.placeId) {
      try {
        const response = await LocationApiService.getPlaceResults(placeidProps) as Place[];

        setIsRunning(false);
        setResponse(response);
      } catch (err) {
        setError(err);
        setHasError(true);
        setIsRunning(false);
      }
    }

  }, [placeidProps.placeId]);

  useEffect(() => {
    apiCall();
  }, [placeidProps.placeId]);

  return [apiStatus];
};

export default usePlaceApi;
